import { ENV } from "./config/properties";

const app_apis = {
    getTranslations: {
        basePath: {
            env: {
                'DEV': 'http://previewdev.parker.com',
                'TEST': 'http://previewdev.parker.com',
                'STAGE': 'http://previewdev.parker.com',
                'PROD': 'http://preview.parker.com'
            }
        }, relativePath: '/TranslationRestAPI/Translation/getTranslations'
    },
    migrationAuthenticate: {
        basePath: {
            env: {
                'DEV': 'https://phdev.parker.com',
                'TEST': 'https://phtest.parker.com',
                'STAGE': 'https://phstage.parker.com',
                'PROD': 'https://cdnph.parker.com'
            }
        }, relativePath: '/wcs/resources/store/10151/loginidentity'
    },
    migrationUpdate: {
        basePath: {
            env: {
                'DEV': 'https://phdev.parker.com',
                'TEST': 'https://phtest.parker.com',
                'STAGE': 'https://phstage.parker.com',
                'PROD': 'https://cdnph.parker.com'
            }
        }, relativePath: '/wcs/resources/api/sso/user/update'
    },
    getTokenUrl: {
        basePath: {
            env: {
                'DEV': 'https://parkerexternal.oktapreview.com',
                'TEST': 'https://phqa.oktapreview.com',
                'STAGE': 'https://phstage.oktapreview.com',
                'PROD': 'https://parkerexternal.okta.com'
            }
        }, relativePath: '/api/v1/authn/recovery/token'
    },
    migrationFinish: {
        basePath: {
            env: {
                'DEV': 'https://oktadev-api.parker.com',
                'TEST': 'https://oktaqa-api.parker.com',
                'STAGE': 'https://oktastage-api.parker.com',
                'PROD': 'https://okta-api.parker.com'
            }
        }, relativePath: '/migration/finish'
    },
    changePassword: {
        basePath: {
            env: {
                'DEV': 'https://apidev.parker.com/dev',
                'TEST': 'https://apidev.parker.com/test',
                'STAGE': 'https://api.parker.com/stage',
                'PROD': 'https://api.parker.com/prod'
            }
        }, relativePath: '/okta/authn/credentials/reset_password'
    },
    addUserToGroup: {
        basePath: {
            env: {
                'DEV': 'https://apidev.parker.com/dev',
                'TEST': 'https://apidev.parker.com/test',
                'STAGE': 'https://api.parker.com/stage',
                'PROD': 'https://api.parker.com/prod'
            }
        }, relativePath: '/okta/groups/#groupid#/users/#userid#'
    },
    getPCMUrl: {
        basePath: {
            env: {
                'DEV': 'https://apidev.parker.com/dev',
                'TEST': 'https://apidev.parker.com/test',
                'STAGE': 'https://api.parker.com/stage',
                'PROD': 'https://api.parker.com/prod'
            }
        }, relativePath: '/okta/users/guid/groups'
    },
   
    
    getFooterUrl: {
        basePath: {
            env: {
                'DEV': 'https://phdev.parker.com',
                'TEST': 'https://phtest.parker.com',
                'STAGE': 'https://phstage.parker.com',
                'PROD': 'https://ph.parker.com'
            }
        }, relativePath: '/wcs/resources/store/22952/parkerheaderfooter/bysectiontype/Footer'
    },
    getRegisterUrl:{
        basePath: {
            env: {
                'DEV': 'https://apidev.parker.com/dev',
                'TEST': 'https://apidev.parker.com/test',
                'STAGE': 'https://api.parker.com/stage',
                'PROD': 'https://api.parker.com/prod'
            }
        }, relativePath: '/okta/users?activate=false'
    },
    getActivateUrl:{
        basePath: {
            env: {
                'DEV': 'https://apidev.parker.com/dev',
                'TEST': 'https://apidev.parker.com/test',
                'STAGE': 'https://api.parker.com/stage',
                'PROD': 'https://api.parker.com/prod'
            }
        }, relativePath: '/okta/users/#userGuid#/lifecycle/activate?sendEmail=false'
    },
    getUserDetails:{
        basePath: {
            env: {
                'DEV': 'https://apidev.parker.com/dev',
                'TEST': 'https://apidev.parker.com/test',
                'STAGE': 'https://api.parker.com/stage',
                'PROD': 'https://api.parker.com/prod'
            }
        }, relativePath: '/okta/users/'
    },
    getForgortPassword:{
        basePath: {
            env: {
                'DEV': 'https://apidev.parker.com/dev',
                'TEST': 'https://apidev.parker.com/test',
                'STAGE': 'https://api.parker.com/stage',
                'PROD': 'https://api.parker.com/prod'
            }
        }, relativePath: '/okta/password/reset/byUserId/#userEmail#/for/#domainName#'
    },
    getJobsAPIUrl:{
        basePath: {
            env: {
                'DEV': 'https://apidev.parker.com/dev',
                'TEST': 'https://apidev.parker.com/test',
                'STAGE': 'https://api.parker.com/stage',
                'PROD': 'https://api.parker.com/prod'
            }
        }, relativePath: '/smartcomm/api/v5/jobs'
    },
    getUsersFromGroup:{
        basePath: {
            env: {
                'DEV': 'https://apidev.parker.com/dev',
                'TEST': 'https://apidev.parker.com/test',
                'STAGE': 'https://api.parker.com/stage',
                'PROD': 'https://api.parker.com/prod'
            }
        }, relativePath: '/okta/groups/#groupid#/users'
    },
    getGroupsFromUser:{
        basePath: {
            env: {
                'DEV': 'https://apidev.parker.com/dev',
                'TEST': 'https://apidev.parker.com/test',
                'STAGE': 'https://api.parker.com/stage',
                'PROD': 'https://api.parker.com/prod'
            }
        }, relativePath: '/okta/users/#userid#/groups'
    },
    getAppInfo:{
        basePath: {
            env: {
                'DEV': 'https://apidev.parker.com/dev',
                'TEST': 'https://apidev.parker.com/test',
                'STAGE': 'https://api.parker.com/stage',
                'PROD': 'https://api.parker.com/prod'
            }
        }, relativePath: '/okta/apps/#appid#/'
    },
    getCurrentUser:{
        basePath: {
            env: {
                'DEV': 'https://logintest.parker.com',
                'TEST': 'https://loginqa.parker.com',
                'STAGE': 'https://loginstage.parker.com',
                'PROD': 'https://login.parker.com'
            }
        }, relativePath: '/api/v1/sessions/me'
    },
    addUserToSaba:{
        basePath: {
            env: {
                'DEV': 'https://apidev.parker.com/dev',
                'TEST': 'https://apidev.parker.com/test',
                'STAGE': 'https://api.parker.com/stage',
                'PROD': 'https://api.parker.com/prod'
            }
        }, 
        relativePath: '/saba/create-user'
    },
    deleteUserToSaba:{
        basePath: {
            env: {
                'DEV': 'https://apidev.parker.com/dev',
                'TEST': 'https://apidev.parker.com/test',
                'STAGE': 'https://api.parker.com/stage',
                'PROD': 'https://api.parker.com/prod'
            }
        }, 
        relativePath: '/saba/delete-saba-user'
    }
};

const app_paths = {
    getOKTASubscriptionKey: {
        basePath: {
            env: {
                'DEV': 'bef61c346f4445b0a835bf9759f9cd02',
                'TEST': 'c89a2e857cfa43869c474e2069aaa90e',
                'STAGE': 'c89a2e857cfa43869c474e2069aaa90e',
                'PROD': 'c89a2e857cfa43869c474e2069aaa90e'
            }
        }
    },
    redirectUrl: {
        basePath: {
            env: {
                'DEV': 'https://logintest.parker.com',
                'TEST': 'https://loginqa.parker.com',
                'STAGE': 'https://loginstage.parker.com',
                'PROD': 'https://login.parker.com'
            }
        }
    },
    redirectUrlTfs: {
        basePath: {
            env: {
                'DEV': 'https://logintest.totalfiltrationservices.com',
                'TEST': 'https://loginqa.totalfiltrationservices.com',
                'STAGE': 'https://loginstage.totalfiltrationservices.com',
                'PROD': 'https://login.totalfiltrationservices.com'
            }
        }
    },
    redirectUrlBaldwin: {
        basePath: {
            env: {
                'DEV': 'https://logintest.baldwinfilters.com',
                'TEST': 'https://loginqa.baldwinfilters.com',
                'STAGE': 'https://loginstage.baldwinfilters.com',
                'PROD': 'https://login.baldwinfilters.com'
            }
        }
    },
    getEmail: {
        basePath: {
            env: {
                'DEV': 'donotreply@parker.com',
                'TEST': 'donotreply@parker.com',
                'STAGE': 'donotreply@parker.com',
                'PROD': 'donotreply@parker.com'
            }
        }
    },
    getAuthorizationKey: {
        basePath: {
            env: {
                'DEV': 'QASecretKeyForMigrationPage',
                'TEST': 'QASecretKeyForMigrationPage',
                'STAGE': 'QASecretKeyForMigrationPage',
                'PROD': '7788KeyToInvokeMigrationService7788'
            }
        }
    },
    getTFSReqContext: {
        basePath: {
            env: {
                'DEV': 'https%3A%2F%2Fphdev.parker.com%2FSSOLoginPage%3FcatalogId%3D35151%26langId%3D-1%26SSOURL%3DaHR0cHM6Ly9waGRldi5wYXJrZXIuY29tL3Rmcy9lbi9NeUFjY291bnQ%253D%26storeId%3D22952',
                'TEST': 'https%3A%2F%2Fphtest.totalfiltrationservices.com%2FSSOLoginPage%3FcatalogId%3D35151%26langId%3D-1%26SSOURL%3DaHR0cHM6Ly9waHRlc3QudG90YWxmaWx0cmF0aW9uc2VydmljZXMuY29tL3Rmcy9lbi9NeUFjY291bnQ%253D%26storeId%3D22952',
                'STAGE': 'https%3A%2F%2Fphstage.totalfiltrationservices.com%2FSSOLoginPage%3FcatalogId%3D35151%26langId%3D-1%26SSOURL%3DaHR0cHM6Ly9waHN0YWdlLnRvdGFsZmlsdHJhdGlvbnNlcnZpY2VzLmNvbS90ZnMvZW4vTXlBY2NvdW50%26storeId%3D22952',
                'PROD': 'https%3A%2F%2Fph.totalfiltrationservices.com%2FSSOLoginPage%3FcatalogId%3D35151%26langId%3D-1%26SSOURL%3DaHR0cHM6Ly9waC50b3RhbGZpbHRyYXRpb25zZXJ2aWNlcy5jb20vdGZzL2VuL015QWNjb3VudA%253D%253D%26storeId%3D22952'
            }
        }
    },
    getTFSTnC:{
        basePath: {
            env: {
                'DEV': 'https://test.totalfiltrationservices.com/parkerimages/TFS/Terms%20and%20Privacy/Total%20Filtration%20Services%20TCs.pdf',
                'TEST': 'https://test.totalfiltrationservices.com/parkerimages/TFS/Terms%20and%20Privacy/Total%20Filtration%20Services%20TCs.pdf',
                'STAGE': 'https://test.totalfiltrationservices.com/parkerimages/TFS/Terms%20and%20Privacy/Total%20Filtration%20Services%20TCs.pdf',
                'PROD': 'https://test.totalfiltrationservices.com/parkerimages/TFS/Terms%20and%20Privacy/Total%20Filtration%20Services%20TCs.pdf'
            }
        }
    },
    getTFSPP:{
        basePath: {
            env: {
                'DEV': 'https://test.totalfiltrationservices.com/parkerimages/TFS/Terms%20and%20Privacy/Total%20Filtration%20Services%20PP.pdf',
                'TEST': 'https://test.totalfiltrationservices.com/parkerimages/TFS/Terms%20and%20Privacy/Total%20Filtration%20Services%20PP.pdf',
                'STAGE': 'https://test.totalfiltrationservices.com/parkerimages/TFS/Terms%20and%20Privacy/Total%20Filtration%20Services%20PP.pdf',
                'PROD': 'https://test.totalfiltrationservices.com/parkerimages/TFS/Terms%20and%20Privacy/Total%20Filtration%20Services%20PP.pdf'
            }
        }
    },
    getTnC:{
        basePath: {
            env: {
                'DEV': 'http://previewdev.parker.com/portal/site/PARKER/menuitem.9901ac95711d8c5906b58f95427ad1ca/?vgnextoid=1b487782259b6310VgnVCM10000014a71dacRCRD&vgnextchannel=2b4955b4d8876310VgnVCM10000014a71dacRCRD',
                'TEST': 'http://test.parker.com/portal/site/PARKER/menuitem.9901ac95711d8c5906b58f95427ad1ca/?vgnextoid=1b487782259b6310VgnVCM10000014a71dacRCRD&vgnextchannel=2b4955b4d8876310VgnVCM10000014a71dacRCRD',
                'STAGE': 'http://preview.parker.com/portal/site/PARKER/menuitem.9901ac95711d8c5906b58f95427ad1ca/?vgnextoid=1b487782259b6310VgnVCM10000014a71dacRCRD&vgnextchannel=2b4955b4d8876310VgnVCM10000014a71dacRCRD',
                'PROD': 'http://www.parker.com/portal/site/PARKER/menuitem.9901ac95711d8c5906b58f95427ad1ca/?vgnextoid=1b487782259b6310VgnVCM10000014a71dacRCRD&vgnextchannel=2b4955b4d8876310VgnVCM10000014a71dacRCRD'
            }
        }
    },
    getPP:{
        basePath: {
            env: {
                'DEV': 'http://previewdev.parker.com/portal/site/PARKER/menuitem.4450f18f18c082cdfd40eae8237ad1ca/?vgnextoid=760b904cf58b2110VgnVCM100000c9040d0aRCRD',
                'TEST': 'http://test.parker.com/portal/site/PARKER/menuitem.4450f18f18c082cdfd40eae8237ad1ca/?vgnextoid=760b904cf58b2110VgnVCM100000c9040d0aRCRD',
                'STAGE': 'http://preview.parker.com/portal/site/PARKER/menuitem.4450f18f18c082cdfd40eae8237ad1ca/?vgnextoid=760b904cf58b2110VgnVCM100000c9040d0aRCRD',
                'PROD': 'http://www.parker.com/portal/site/PARKER/menuitem.4450f18f18c082cdfd40eae8237ad1ca/?vgnextoid=760b904cf58b2110VgnVCM100000c9040d0aRCRD'
            }
        }
    },
    getParkerHomePage:{
        basePath: {
            env: {
                'DEV': 'https://dev.parker.com',
                'TEST': 'https://test.parker.com',
                'STAGE': 'https://preview.parker.com',
                'PROD': 'https://parker.com'
            }
        }
    },
    getTFSHomePage:{
        basePath: {
            env: {
                'DEV': 'https://dev.totalfiltrationservices.com',
                'TEST': 'https://test.totalfiltrationservices.com',
                'STAGE': 'https://preview.totalfiltrationservices.com',
                'PROD': 'https://totalfiltrationservices.com'
            }
        }
    },
    getBaldwinHomePage:{
        basePath: {
            env: {
                'DEV': 'https://dev.baldwinfilters.com',
                'TEST': 'https://test.baldwinfilters.com',
                'STAGE': 'https://preview.baldwinfilters.com',
                'PROD': 'https://baldwinfilters.com'
            }
        }
    },
    getTFSRedirectUrl:{
        basePath: {
            env: {
                'DEV': 'https://oktatest.parker.com',
                'TEST': 'https://oktaqa.totalfiltrationservices.com',
                'STAGE': 'https://oktaqa.totalfiltrationservices.com',
                'PROD': 'https://oktaqa.totalfiltrationservices.com'
            }
        }
    },
    getMyParkerDashBoardSSO:{
        basePath: {
            env: {
                'DEV': 'https://logintest.parker.com/home/oidc_client/0oa14kxt7wm4ymyf60h8/aln5z7uhkbM6y7bMy0g7',
                'TEST': 'https://loginqa.parker.com/home/oidc_client/0oa14kyajegQqf0DV0h8/aln5z7uhkbM6y7bMy0g7',
                'STAGE': 'https://loginstage.parker.com/home/oidc_client/0oa155s71agg4fl5E0h8/aln5z7uhkbM6y7bMy0g7',
                'PROD': 'https://login.parker.com/home/oidc_client/0oafelvonflA2oIzp2p7/aln177a159h7Zf52X0g8'
            }
        }
    },
    getOCMSubscriptionKey:{
        basePath: {
            env: {
                'DEV': 'c4ed542738c740698cbf2c35eb3594d8',
                'TEST': '3582726943d84cdc9647533f5559058a',
                'STAGE': '',
                'PROD': ''
            }
        }
    },
    getJObAPIOCMSubscriptionKey:{
        basePath: {
            env: {
                'DEV': 'c926f915fa8f4305866ca03c51d98e6a',
                'TEST': '103366adaf444f879455e3cdaea053c9',
                'STAGE': '103366adaf444f879455e3cdaea053c9',
                'PROD': '103366adaf444f879455e3cdaea053c9'
            }
        }
    },
    getSmartCommQueue:{
        basePath: {
            env: {
                'DEV': 'Parker_Queue_DEV',
                'TEST': 'Parker_Queue_DEV',
                'STAGE': 'Parker_Queue_PROD',
                'PROD': 'Parker_Queue_PROD'
            }
        }
    },
    getPCMLinkURL:{
        basePath: {
            env: {
                'DEV': 'https://phc-pcm-dev-webapp.azurewebsites.net/login',
                'TEST': 'https://pcmtest.parker.com/login',
                'STAGE': 'https://pcmtest.parker.com/login',
                'PROD': 'https://pcmvom.parker.com/login'
            }
        }
    },
    getPhConnectUrl:{
        basePath: {
            env: {
                'DEV': 'https://phcdev.parker.corp',
                'TEST': 'https://phctest.parker.com',
                'STAGE': 'https://phctest.parker.com',
                'PROD': 'https://www.phconnect.com'
            }
        }
    },
    getParkerSSOUrl:{
        basePath: {
            env: {
                'DEV': 'https://logintest.parker.com/app/parkerhannifinsandboxapiparkerexternaloktapreview_parkercomwebspheretest_1/exkk0su8zubNYxDoD0h7/sso/saml',
                'TEST': 'https://loginqa.parker.com/app/phqa_parkercomwebsphereqa_1/exkofhhomyPE7XzaI0h7/sso/saml',
                'STAGE': 'https://loginqa.parker.com/app/phqa_parkercomwebsphereqa_1/exkofhhomyPE7XzaI0h7/sso/saml',
                'PROD': 'https://loginqa.parker.com/app/phqa_parkercomwebsphereqa_1/exkofhhomyPE7XzaI0h7/sso/saml'
            }
        }
    },
    getTFSSSOUrl:{
        basePath: {
            env: {
                'DEV': 'https://logintest.parker.com/app/parkerhannifindev_tfsicomwebspheredev_1/exksj4f4stV5QB7cD0h7/sso/saml',
                'TEST': 'https://loginqa.parker.com/app/parkerhannifinqa_tfsicomwebsphereqa_1/exkoo014xrNamD6ML0h7/sso/saml',
                'STAGE': 'https://loginstage.parker.com/app/parkerhannifinstage_parkercomstage_1/exkororw2m2GkguhH0h7/sso/saml',
                'PROD': 'https://login.parker.com/app/parkerhannifinprodapiparkerexternal_parkercom_1/exk5cumgoisYcZ4rw2p7/sso/saml'
            }
        }
    },
    getBaldwinSSOUrl:{
        basePath: {
            env: {
                'DEV': 'https://logintest.parker.com/app/parkerexternal_baldwinfilterscomdev_1/exkv6igc0nacdRGxL0h7/sso/saml',
                'TEST': 'https://loginqa.parker.com/app/phqa_baldwinfilterscomqa_1/exkw4l90cobUATcR40h7/sso/saml',
                'STAGE': 'https://loginstage.parker.com/app/phstage_baldwinfilterscomstage_1/exk10unkqzppyW5J70h8/sso/saml',
                'PROD': 'https://login.parker.com/app/parkerexternal_baldwinfilterscom_1/exkc93lol1kAMkldV2p7/sso/saml'
            }
        }
    },
    getTFS_HOME_SP_SSO_PAGE:{
        basePath: {
            env: {
                'DEV': 'https://phdev.parker.com/SSOLoginPage?storeId=22952&catalogId=35151&langId=-1&SSOURL=aHR0cHM6Ly9kZXYudG90YWxmaWx0cmF0aW9uc2VydmljZXMuY29t',
                'TEST': 'https://phtest.totalfiltrationservices.com/SSOLoginPage?storeId=22952&catalogId=35151&langId=-1&SSOURL=aHR0cHM6Ly90ZXN0LnRvdGFsZmlsdHJhdGlvbnNlcnZpY2VzLmNvbQ==',
                'STAGE': 'https://phtest.totalfiltrationservices.com/SSOLoginPage?storeId=22952&catalogId=35151&langId=-1&SSOURL=aHR0cHM6Ly90ZXN0LnRvdGFsZmlsdHJhdGlvbnNlcnZpY2VzLmNvbQ==',
                'PROD': 'https://phtest.totalfiltrationservices.com/SSOLoginPage?storeId=22952&catalogId=35151&langId=-1&SSOURL=aHR0cHM6Ly90ZXN0LnRvdGFsZmlsdHJhdGlvbnNlcnZpY2VzLmNvbQ=='
            }
        }
    },
    getBALDWIN_HOME_SP_SSO_PAGE:{
        basePath: {
            env: {
                'DEV': 'https://phdev.baldwinfilters.com/SSOLoginPage?storeId=22951&catalogId=34651&langId=-1&SSOURL=aHR0cHM6Ly9kZXYuYmFsZHdpbmZpbHRlcnMuY29tLw%3D%3D',
                'TEST': 'https://phtest.baldwinfilters.com/SSOLoginPage?storeId=22951&catalogId=34651&langId=-1&SSOURL=aHR0cHM6Ly90ZXN0LmJhbGR3aW5maWx0ZXJzLmNvbS8',
                'STAGE': 'https://phstage.baldwinfilters.com/SSOLoginPage?storeId=22951&catalogId=34651&langId=-1&SSOURL=1aHR0cHM6Ly9wcmV2aWV3LmJhbGR3aW5maWx0ZXJzLmNvbS8',
                'PROD': 'https://ph.baldwinfilters.com/SSOLoginPage?storeId=22951&catalogId=34651&langId=-1&SSOMode=1&&SSOURL=aHR0cHM6Ly93d3cuYmFsZHdpbmZpbHRlcnMuY29tLw%3D%3D'
            }
        }
    },
    getBaldwinReqContext: {
        basePath: {
            env: {
                'DEV': 'https://logintest.parker.com/app/parkerexternal_baldwinfilterscomdevazure_1/exk10tk4w5ny0mihp0h8/sso/saml',
                'TEST': 'https://loginqa.parker.com/app/phqa_baldwinfilterscomtestazure_1/exk11et9ooiReVVgb0h8/sso/saml',
                'STAGE': 'https://loginstage.parker.com/app/phstage_baldwinfilterscomstageazure_1/exk11essjmoXQ8pFr0h8/sso/saml',
                'PROD': 'https://login.parker.com/app/parkerexternal_baldwinfilterscomazure_1/exkcu6s104knr0Im82p7/sso/saml'
            }
        }
    },
    getBaldwinEmailId: {
        basePath: {
            env: {
                'DEV': 'baldwinaccount@logintest.baldwinfilters.com',
                'TEST': 'baldwinaccount@loginqa.baldwinfilters.com',
                'STAGE': 'baldwinaccount@loginstage.baldwinfilters.com',
                'PROD': 'baldwinaccount@login.baldwinfilters.com'
            }
        }
    },
    getTFSEmailId: {
        basePath: {
            env: {
                'DEV': 'baldwinaccount@logintest.baldwinfilters.com',
                'TEST': 'baldwinaccount@loginqa.baldwinfilters.com',
                'STAGE': 'baldwinaccount@loginstage.baldwinfilters.com',
                'PROD': 'baldwinaccount@login.baldwinfilters.com'
            }
        }
    },
    getPARKER_HOME_SP_SSO_PAGE:{
        basePath: {
            env: {
                'DEV': 'https://phdev.parker.com/SSOLoginPage?storeId=23551&catalogId=14051&langId=-1&SSOMode=1',
                'TEST': 'https://phtest.parker.com/SSOLoginPage?storeId=23551&catalogId=14051&langId=-1&SSOMode=1',
                'STAGE': 'https://phstage.parker.com/SSOLoginPage?storeId=23551&catalogId=14051&langId=-1&SSOMode=1',
                'PROD': 'https://ph.parker.com/SSOLoginPage?storeId=23551&catalogId=14051&langId=-1&SSOMode=1'
            }
        }
    },
    getParkerGroupId:{
        basePath: {
            env: {
                'DEV': '00gmyc3zbdk0vCk5D0h7',
                'TEST': '00goin4rqa3Xz3j2h0h7',
                'STAGE': '00gorojvvt1DxzXzZ0h7',
                'PROD': '00g5cuhy5ymF74d6H2p7'
            }
        }
    },
    getTFSGroupId:{
        basePath: {
            env: {
                'DEV': '00gtbpzphlvScZhSF0h7',
                'TEST': '00gtbpzphlvScZhSF0h7',
                'STAGE': '00gtbpzphlvScZhSF0h7',
                'PROD': '00gtbpzphlvScZhSF0h7'
            }
        }
    },
    getBaldwinGroupId:{
        basePath: {
            env: {
                'DEV': '00gv6ihcb2nqrJG700h7',
                'TEST': '00gwezk0viZ3KU3Lx0h7',
                'STAGE': '00gwezkn20sMXvuar0h7',
                'PROD': '00g9lurdifIHhrQtC2p7'
            }
        }
    },
    getParkerIdAppId:{
        basePath: {
            env: {
                'DEV': '0oa1x15ovmtSpxv0G0h8',
                'TEST': '0oa1uzzay96I6tyJe0h8',
                'STAGE': '0oasycxk91Nebr8gk0h7',
                'PROD': '0oapvjfqmcAGxKnQn2p7'
            }
        }
    },
    getParkerIdApproverGroupId:{
        basePath: {
            env: {
                'DEV': '00guwe8k12MwvMXVz0h7',
                'TEST': '00gv2wspil6jxlNW80h7',
                'STAGE': '00gv2wtac0ZbuCPWI0h7',
                'PROD': '00g94tag83oO0Znsu2p7'
            }
        }
    },
    getParkerIdGroupId:{
        basePath: {
            env: {
                'DEV': '00guxbh2xuse4jnzx0h7',
                'TEST': '00gv2wsumjwEP0Aw60h7',
                'STAGE': '00gv2wurv6Y2nrDQt0h7',
                'PROD': '00g94tbh56Wbzeyod2p7'
            }
        }
    },
    getBaseReactUrl:{
        basePath: {
            env: {
                'DEV': 'https://oktatest.parker.com',
                'TEST': 'https://oktaqa.parker.com',
                'STAGE': 'https://oktastage.parker.com',
                'PROD': 'https://okta.parker.com'
            }
        }
    },
    getApproverClientID:{
        basePath: {
            env: {
                'DEV': '0oav58n0wnGNSfGC20h7',
                'TEST': '0oav5uydfpt1tBGBC0h7',
                'STAGE': '0oavltqgpzsTxXVfG0h7',
                'PROD': '0oa94tfmp2hCOyAAF2p7'
            }
        }
    },
    getTFSHelpLink:{
        basePath: {
            env: {
                'DEV': 'https://dev.totalfiltrationservices.com/portal/site/TFS/menuitem.fe52241bc878067a12187a755b8ad1ca/?vgnextoid=f6cf4ab0357ac710VgnVCM100000b4a81dacRCRD&appInstanceName=default',
                'TEST': 'https://test.totalfiltrationservices.com/portal/site/TFS/menuitem.fe52241bc878067a12187a755b8ad1ca/?vgnextoid=f6cf4ab0357ac710VgnVCM100000b4a81dacRCRD&appInstanceName=default',
                'STAGE': 'https://preview.totalfiltrationservices.com/portal/site/TFS/menuitem.fe52241bc878067a12187a755b8ad1ca/?vgnextoid=f6cf4ab0357ac710VgnVCM100000b4a81dacRCRD&appInstanceName=default',
                'PROD': 'https://www.totalfiltrationservices.com/portal/site/TFS/menuitem.fe52241bc878067a12187a755b8ad1ca/?vgnextoid=f6cf4ab0357ac710VgnVCM100000b4a81dacRCRD&appInstanceName=default'
            }
        }
    },
    getBaldwinHelpLink:{
        basePath: {
            env: {
                'DEV': 'https://test.baldwinfilters.com/us/en/sign-in-help.html',
                'TEST': 'https://test.baldwinfilters.com/us/en/sign-in-help.html',
                'STAGE': 'https://test.baldwinfilters.com/us/en/sign-in-help.html',
                'PROD': 'https://www.baldwinfilters.com/us/en/sign-in-help.html'
            }
        }
    },
    getLeanerAppId:{
        basePath: {
            env: {
                'DEV': '0oar3hctu0uvmNIbW0h7',
                'TEST': '0oar3hctu0uvmNIbW0h7',
                'STAGE': '0oa7jcbqim6a3sBpf2p7',
                'PROD': '0oa7jcbqim6a3sBpf2p7'
            }
        }
    },
    getLeanerApproverGroupId:{
        basePath: {
            env: {
                'DEV': { interest_1: '00g166glsiqnd3lLE0h8', interest_2: '00g166hhaqcnVLf4E0h8', interest_3: '00g19t1peb5vtx0k90h8', interest_4: '00g1vidasv96z1RUW0h8', interest_5: '00g22q1xfbrIbqi8m0h8' },
                'TEST': { interest_1: '00g166glsiqnd3lLE0h8', interest_2: '00g166hhaqcnVLf4E0h8', interest_3: '00g19t1peb5vtx0k90h8', interest_4: '00g1vidasv96z1RUW0h8', interest_5: '00g22q1xfbrIbqi8m0h8' },
                'STAGE': { interest_1: '00ggdtazndiSMYDNV2p7', interest_2: '00ggdtczdgrZKiTLZ2p7', interest_3: '00gh3fmre1matCR9t2p7', interest_4:'00gpiavzgzp46TdIv2p7', interest_5: '00gvh4oxcv3JD2Ysc2p7' },
                'PROD': { interest_1: '00ggdtazndiSMYDNV2p7', interest_2: '00ggdtczdgrZKiTLZ2p7', interest_3: '00gh3fmre1matCR9t2p7', interest_4:'00gpiavzgzp46TdIv2p7', interest_5: '00gvh4oxcv3JD2Ysc2p7' }
            }
        }
    },
    getPLISubscriptionKey: {
        basePath: {
            env: {
                'DEV': '9ed8784184f6430eb4a0fc4ef6e33ba6',
                'TEST': '9ed8784184f6430eb4a0fc4ef6e33ba6',
                'STAGE': '2fb29d68be7f4c6b9254324bdcb51fa2',
                'PROD': '2fb29d68be7f4c6b9254324bdcb51fa2'
            }
        }
    },
    getPLIGroupId: {
        basePath: {
            env: {
                'DEV': '00g16mqgwhvtuJRbj0h8',
                'TEST': '00g16mqgwhvtuJRbj0h8',
                'STAGE': '00ggdtcfrgm0KvVyz2p7',
                'PROD': '00ggdtcfrgm0KvVyz2p7'
            }
        }
    },
    getStoreAppId:{
        basePath: {
            env: {
                'DEV': '0oa1zktrbiqmJ9uWo0h8',
                'TEST': '0oa1zkvhlp2hUWA4C0h8',
                'STAGE': '0oa1zkvhlp2hUWA4C0h8',
                'PROD': '0oas4y8phoPmJZBtc2p7'
            }
        }
    },
	 getDistributorAppId:{
        basePath: {
            env: {
                'DEV': '0oa1zkxf4lquHwX990h8',
                'TEST': '0oa1zkyt4dxpZyHhW0h8',
                'STAGE': '0oa1zkyt4dxpZyHhW0h8',
                'PROD': '0oas4zduq8RippR6D2p7'
            }
        }
    },
    getStoreApproverGroupId:{
        basePath: {
            env: {
                'DEV': '00guwe8k12MwvMXVz0h7',
                'TEST': '00gv2wspil6jxlNW80h7',
                'STAGE': '00gv2wtac0ZbuCPWI0h7',
                'PROD': '00g94tag83oO0Znsu2p7'
            }
        }
    },
    getDistributorApproverGroupId:{
        basePath: {
            env: {
                'DEV': '00guwe8k12MwvMXVz0h7',
                'TEST': '00gv2wspil6jxlNW80h7',
                'STAGE': '00gv2wtac0ZbuCPWI0h7',
                'PROD': '00g94tag83oO0Znsu2p7'
            }
        }
    }
}

export function getAPIUrl(api) {
    const envType = ENV;
    return app_apis[api].basePath["env"][envType] + app_apis[api]['relativePath'];
}

export function getPath(api) {
    const envType = ENV;
    return app_paths[api].basePath["env"][envType];
}